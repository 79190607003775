/* body{
    background-color: #e9ecef;
} */

label{
    margin-bottom: 0px !important;
}

.geoapify-autocomplete-input {
    padding: 0 30px 0 5px;
    width: calc(100% - 40px);

    outline: none;

    line-height: 20px;
    height: 32px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 5px;

    font-size: 12px;
}

.geocoder-container{
    margin-top: 4px;
}

.geoapify-autocomplete-items {
    position: absolute;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-top: none;
    background-color: #fff;
    
    z-index: 99;
    top: 100%;
    left: 0;
    right: 0;
}

.geocoder-container {
    margin-bottom: 10px;
}

.geoapify-autocomplete-items div {
    padding: 10px;
    cursor: pointer;
}

.geoapify-autocomplete-items div:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.geoapify-autocomplete-items .active {
    background-color: rgba(0, 0, 0, 0.1);
}



.geoapify-autocomplete-item {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.geoapify-autocomplete-item .icon {
    display: inline-block;
    width: 40px;
    height: 24px;
    color: #aaa;
}

.geoapify-autocomplete-item .icon.emoji {
    color: unset;
    font-size: 20px;
    opacity: 0.9;
}

.geoapify-close-button {
    position: absolute;
    right: 5px;
    top: 0;

    height: 100%;
    display: none;
    align-items: center;
}

.geoapify-close-button.visible {
    display: flex;
}

.geoapify-close-button {
    color: rgba(0, 0, 0, 0.4);
    cursor: pointer;
}

.geoapify-close-button:hover {
    color: rgba(0, 0, 0, 0.6);
}

.geoapify-autocomplete-items .main-part .non-verified {
    color:  #ff4848;
}

.geoapify-autocomplete-items .secondary-part {
    margin-left: 10px;
    font-size: small;
    color: rgba(0, 0, 0, 0.6);
}

#my-map {
    width: 100%;
    height: 100%;
    margin: 0;
  }

.logo{
    width: 80px;
    text-shadow: 2px 2px 4px #000000;
    display: inline-block;
    padding-bottom: 21px;
}

.header{
    background-color: #e9ecef;
}

.header-text{
    font-family: "Orbitron", sans-serif;
    padding-top: 24px;
    padding-bottom: 20px;
    display: inline-block;
    padding-left: 15px;
}

.auth-header-text{
    font-family: "Orbitron", sans-serif;
    padding-top: 10px;
    padding-bottom: 20px;
}

.auth-button{
    margin-top:31px;
    float: right;
}

.auth-tooltip-entry{
    position: absolute;
    bottom: 0;
}

.auth-tooltip ol{
    text-align: left;
}


.account-label-container{
    padding-right: 0px !important;
}

.account-label{
    margin-top:37px;
    float: right;
}

.spacer{
    width: 15px;
    display: inline-block;
}

.card{
    padding: 25px 15px;
}

.login-container{
    margin-top: 150px;
}

.account-select{
    margin-top: 31px;
}

.auth-input{
    margin-bottom: 15px;
}

.custom-row-container-width{
    max-width:1400px;
}

.content-container{
    background-color: #fff;
    border-style: solid;
    border-width: 1px;
    border-color: #b4b4b4;
    border-radius: 5px;
    box-shadow: 0px 2px 3px #aaaaaa;
    padding: 15px;
}

.margin-top-spacer{
    margin-top: 15px;
}



/* .col-custom-left {
    padding-right: 200px;
} */

input[type="checkbox"] {
    flex: none;
    margin-top: 3px;
 }
.form-check-label{
    display: flex;
    align-items: center;
    padding: 5px;
    margin-left: 15px;
    padding-top: 10px;
}

.field{
    margin-bottom: 3px !important;
}

.vert-spacer{
    margin-top:15px;
}

.eqType-label{
    width: 100%;
}

.accordion-button{
    border-radius: 5px !important;
    border-width: 0px !important;
    font-size: 20px;
    font-weight: 700;
    /* background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right .75rem center/8px 10px; */
    background-color:#fff;
    color: #474747;
    padding: 0px !important;
    margin: 0;
    position: absolute;
    top: 65%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.accordion-button:focus{
    outline: 0px auto -webkit-focus-ring-color;
}

.collapsed{
    padding: 0px !important;
}

.sell-rate-wrapper{
    padding-right: 0px !important;
    
}

.alert-rate{
    color: red;
    margin-bottom: 0px !important;
    padding-top: 5px;
}

.error-border{
    border: 1px solid red;

}

.padding-left {
    margin-left: 15px;
}

.sell-rate{
    margin-top: 7px;
    float: right;
}
.email-display{
    min-height: 140px;
}


.table-section{
    background-color:#e9ecef;
    margin-top: 12px;
}
.table-wrapper{
    height:500px;
    padding: 15px;
    background-color: #fff;
    border-style: solid;
    border-width: 1px;
    border-color: #b4b4b4;
    border-radius: 5px;
    width: 100%;
    box-shadow: 0px 2px 3px #aaaaaa;

    /* max-height: 500px;
    display: flex;
    flex-direction: column;
    overflow: hidden; */
}



/* .table-wrapper > div {
    overflow-y: auto !important;
} */

table{
    background-color: #fff;
}

.footer{
    background-color: #e9ecef;
}

/* autocomplete */

.geoapify-autocomplete-input {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem !important;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.btn-stop {
    background-color: #fff;
    padding: 0px;
    border: 2px solid #ccc;
    width: 35px;
    height: 35px;
    font-size: 20px;
    display: block;
    text-align: center;
    text-decoration: none;
    color: black;
}

.btn-stop-top {
    border-bottom-left-radius: 0%;
    border-bottom-right-radius: 0%;
    border-bottom-width: 1px;
}

.btn-stop-bottom {
    border-top-left-radius: 0%;
    border-top-right-radius: 0%;
    border-bottom-width: 1px;

}

.btn-swap {
    border: none !important;
    
    width: 15px !important;
    height: 25px !important;
    
    

}

/* .geocoder-container{
    margin-top: 5px;
} */

.btn-swap:focus{
    box-shadow: none !important;
}

.card-stop {
    margin-top: 24px;
}

.btn-won{
    height: 25px;
    display: flex;
    align-content: center;
    justify-content: center;
    font-size: 12px;
    line-height: 1.1;
    background-color: #219e21;
    border: none;
    margin: 1px
}
.btn-won:hover{
    background-color: #1a831a;
}

.btn-clear{
    height: 25px;
    display: flex;
    align-content: center;
    justify-content: center;
    font-size: 12px;
    line-height: 1.1;
    background-color: #e6be48;
    border: none;
    margin: 1px
}
.btn-clear:hover{
    background-color: #c1a13f;
}

.btn-loss{
    height: 25px;
    display: flex;
    align-content: center;
    justify-content: center;
    font-size: 12px;
    line-height: 1.1;
    background-color: #9e2121;
    border: none;
    margin: 1px
}

.btn-loss:hover{
    background-color: #6b1414;
}

.autocomplete {
    /*the container must be positioned relative:*/
    position: relative;
    display: inline-block;
  }

  .autocomplete-items {
    position: absolute;
    border: 1px solid #d4d4d4;
    border-bottom: none;
    border-top: none;
    z-index: 99;
    /*position the autocomplete items to be the same width as the container:*/
    top: 100%;
    left: 0;
    right: 0;
  }
  .autocomplete-items div {
    padding: 10px;
    cursor: pointer;
    background-color: #fff;
    border-bottom: 1px solid #d4d4d4;
  }
  .autocomplete-items div:hover {
    /*when hovering an item:*/
    background-color: #e9e9e9;
  }
  .autocomplete-active {
    /*when navigating through the items using the arrow keys:*/
    background-color: DodgerBlue !important;
    color: #ffffff;
  }

  .calc-container{
    margin-top: 40px;
  }

  .calc-label{
    font-size: 11px;
    font-weight: 600;
  }

  .btn-calc{
    margin-left: 15px;
    margin-top: 15px;
  }

  .style-operator{
    position: absolute;
    left: -5px;
    top: 29px;
  }

  .arith-operator-container{
    text-align: center;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .arith-operator{
    margin: 0;
    position: absolute;
    top: 65%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .verify-container{
    text-align: center;
  }

  .verify-text{
    margin-top: 30px;
    position: relative;
    /* top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%); */

    padding: 10px;
    background-color: #fff;
    border: 1px solid #d4d4d4;
    border-radius: 5px;
    box-shadow: 0px 2px 3px #aaaaaa;
  }

  .leaflet-container{
    height: 500px;
    border-radius: 5px;
    box-shadow: 0px 2px 3px #aaaaaa;
    border-style: solid;
    border-width: 1px;
    border-color: #b4b4b4;
  }
  .map-blur .leaflet-map-pane{
    filter: blur(5px);
  }

  .leaflet-container .loader-container{
    z-index: 1000;
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    height: 50%;
  }

  .leaflet-container .loader{
    position: absolute;
    margin: auto;
    top: 40%;
    left: 45%;
  }

/* Animations */

.loader-container{
    display: inline-block;
    margin-left: 5px;
}

.loader {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #3498db;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    animation: spin .5s linear infinite;
    margin-bottom: -9px;
  }
  
  @keyframes spin {
    0%  { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

/* utils */

.center{
    margin: auto;
    padding: 10px
}

.center-calculator{
    margin-left: 42px;
    margin-right: 42px;
}

/* media queries */

@media screen and (max-width: 780px) {
    .expand-button-container{
        margin-top: 27px;
        margin-bottom: 15px !important;
    }

    .accordion-button{
        border-width: 1px !important;
        padding: 2px 10px 4px 10px !important;
    }

    .sell-rate{
        float:none;
    }

    .auth-tooltip-entry{
        display: none;
    }

    .leaflet-container{
        margin-top: 15px;
    }

    .account-label-container{
        text-align: center;
    }

    .account-label{
        float: left;
    }

    .auth-button{
        float: left;
        margin-bottom: 15px;
    }
  }